import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const textsize =
    window.innerWidth <= 480 ? "Paragraph4Light" : "Paragraph3Light"
  return (
    <React.Fragment>
      <section className="bg-black text-light py-3">
        {/* <footer className="footer bg-black text-light"> */}
        <Container fluid>
          <Row>
            <Col
              md={6}
              xs={12}
              className={`mb-3 ms-3 ${textsize}`}
              style={{ color: "#B7B7B7" }}
            >
              Copyright 2023 Pinetree Studios Ltd. All rights reserved.
            </Col>
            <Col md={5} xs={12} className="mb-3">
              <Row className="d-flex flex-row-reverse text-md-end ms-3">
                <Col
                  md={3}
                  xs={6}
                  className={`px-0 mx-0 ${textsize}`}
                  style={{ textDecoration: "underline", color: "#B7B7B7" }}
                >
                  <Link
                    to="/terms-and-conditions"
                    className="text-white text-decoration-none"
                  >
                    Terms of Services
                  </Link>
                </Col>
                <Col
                  md={3}
                  xs={6}
                  className={`px-0 mx-0 ${textsize}`}
                  style={{ textDecoration: "underline", color: "#B7B7B7" }}
                >
                  <Link
                    to="/privacy-policy"
                    className="text-white text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* </footer> */}
      </section>
    </React.Fragment>
  )
}

export default Footer
