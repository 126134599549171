import LogoLoader from "components/LogoLoader"
import React, { lazy, Suspense } from "react"
import { Navigate } from "react-router-dom"

// Lazy-loaded pages
const Home = lazy(() => import("../pages/Home/index"))
const About = lazy(() => import("../pages/About/index"))
const Blogs = lazy(() => import("../pages/Blogs"))
const BlogDetails = lazy(() => import("pages/Blogs/BlogDetails"))
const Contact = lazy(() => import("../pages/Contact/index"))
const Portfolio = lazy(() => import("../pages/Portfolio/index"))
const PortfolioDetails = lazy(() => import("pages/Portfolio/PortfolioDetails"))
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"))
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"))
const ThankYou = lazy(() => import("pages/ThankYou"))


// Fallback component to display while loading the lazy-loaded components

const authProtectedRoutes = [
  {
    path: "/home",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/about",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <About />
      </Suspense>
    ),
  },
  {
    path: "/blogs",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Blogs />
      </Suspense>
    ),
  },
  {
    path: "/blog-details",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <BlogDetails />
      </Suspense>
    ),
  },
  {
    path: "/contact",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Contact />
      </Suspense>
    ),
  },
  {
    path: "/portfolio",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Portfolio />
      </Suspense>
    ),
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
  {
    path: "/privacy-policy",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <TermsAndConditions />
      </Suspense>
    ),
  },
  {
    path: "/thank-you",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <ThankYou />
      </Suspense>
    ),
  },
]

const publicRoutes = [
  {
    path: "/",
    component: <Navigate to="/home" />,
  },
  {
    path: "/home",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/about",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <About />
      </Suspense>
    ),
  },
  {
    path: "/portfolio",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Portfolio />
      </Suspense>
    ),
  },
  {
    path: "/portfolio/:tab/:slug",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <PortfolioDetails />
      </Suspense>
    ),
  },
  {
    path: "/blogs",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Blogs />
      </Suspense>
    ),
  },
  {
    path: "/blog-details/:slug",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <BlogDetails />
      </Suspense>
    ),
  },
  {
    path: "/contact",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <Contact />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <TermsAndConditions />
      </Suspense>
    ),
  },
  {
    path: "/thank-you",
    component: (
      <Suspense fallback={<LogoLoader />}>
        <ThankYou />
      </Suspense>
    ),
  },
]

export { authProtectedRoutes, publicRoutes }
