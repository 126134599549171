import React from "react"
import logo from "assets/logos/logoDark.png"

const LogoLoader = () => {
  return (
    <div className="loader-container">
      <img src={logo} alt="Loading..." className="loader-logo" />
    </div>
  )
}

export default LogoLoader
