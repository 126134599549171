import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  changeLayoutMode,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

//components
import Header from "./Header"
import Footer from "./Footer"
import FooterSection from "components/Footer/FooterSection"

const Layout = props => {
  const isMobile = window.innerWidth <= 576
  const dispatch = useDispatch()

  const { topbarTheme, layoutWidth, isPreloader, layoutModeType } = useSelector(
    state => ({
      topbarTheme: state.Layout.topbarTheme,
      layoutWidth: state.Layout.layoutWidth,
      isPreloader: state.Layout.isPreloader,
      showRightSidebar: state.Layout.showRightSidebar,
      layoutModeType: state.Layout.layoutModeType,
    })
  )

  /*
  document title
  */

  const pathName = useLocation()

  useEffect(() => {
    const title = pathName.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = currentage ? currentage + " | PineTree" : "PineTree"
  }, [pathName.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //hides right sidebar on body click
  const hideRightbar = event => {
    var rightbar = document.getElementById("right-bar")
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false))
    }
  }

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"))
  }, [dispatch])

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true)

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [isPreloader])

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType))
    }
  }, [dispatch, layoutModeType])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [dispatch, topbarTheme])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [dispatch, layoutWidth])

  const [showDarkHeader, setShowDarkHeader] = useState(false)
  const [isWhiteButton, setIsWhiteButton] = useState(false)
  const [isDarkLogo, setIsDarkLogo] = useState(false)
  const [showStickyHeader, setShowStickyHeader] = useState(false)

  const isWhiteButtonFn = pathName => {
    const whiteButtonPages = ["/home", "/portfolio"]
    const isWhBtn =
      whiteButtonPages.includes(pathName) ||
      pathName.startsWith("/portfolio") ||
      pathName.startsWith("/blog-details") ||
      pathName === "/"

    return isWhBtn
  }

  useEffect(() => {
    const isDarkTrue =
      pathName.pathname.startsWith("/blog-details") ||
      pathName.pathname.startsWith("/portfolio/photography") ||
      pathName.pathname.startsWith("/portfolio/moving-images")
    if (isDarkTrue) {
      setShowDarkHeader(true)
    } else {
      setShowDarkHeader(false)
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const heroSectionHeight = document.querySelector(".image-section-hero")
        ? document.querySelector(".image-section-hero").clientHeight
        : 200 // default height if hero section is not present
      const calc = scrollPosition > heroSectionHeight * 0.8
      if (!isDarkTrue) {
        setShowDarkHeader(calc)
        // if (isWhBtn) setIsWhiteButton(calc)
        setShowStickyHeader(calc)
      } else {
        setShowStickyHeader(calc)
      }
      const isWhBtn = isWhiteButtonFn(pathName.pathname)
      setIsWhiteButton(isWhBtn || calc)
    }

    // Attach scroll listener for all screen sizes
    window.addEventListener("scroll", handleScroll)

    return () => {
      // Remove scroll listener for all screen sizes
      window.removeEventListener("scroll", handleScroll)
    }
  }, [pathName])

  useEffect(() => {
    const isWhBtn = isWhiteButtonFn(pathName.pathname)
    setIsWhiteButton(isWhBtn)
  }, [pathName.pathname])

  return (
    <React.Fragment>
      <div id="preloader" style={{ display: "none" }}>
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper" className="overflow-x-hidden">
        <Header
          isWhiteButton={isWhiteButton}
          isDark={showDarkHeader}
          isSticky={showStickyHeader}
        />
        <div className="main-content">{props.children}</div>
        <FooterSection />
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayout: PropTypes.func /*  */,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

export default Layout
