import React, { useState } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link, useLocation, useNavigate } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"

// import images
import logo from "assets/images/logo-dark.png"
import Mobilelogo from "assets/images/mobileLogo.svg"
import MobileDarkLogo from "assets/images/mobileLogoDark.svg"
import whiteLogo from "assets/images/White_logo.png"
import whatsapp from "assets/images/menu/Whatsapp.png"
import fb from "assets/images/menu/Facebook.png"
import insta from "assets/images/menu/Instagram.png"
import yt from "assets/images/menu/Youtube.png"

//i18n
import { withTranslation } from "react-i18next"
import { Button, Collapse, NavLink } from "reactstrap"
import "./style.css"
import mobile from "is-mobile"
const Header = props => {
  const { isDark, isWhiteButton, isSticky = false } = props
  const isMobile = mobile()
  const items = ["Home", "About", "Blogs", "Portfolio", "Contact"]
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const pathName = useLocation()
  const darkPagesWhiteLogo = {
    "/privacy-policy": true,
    "/about": false,
    "/blogs": true,
    "/portfolio": true,
    "/contact": false,
    "/terms-and-conditions": true,
  }
  const mobileDarkLogo = {
    "/about": true,
    "/blogs": true,
    "/contact": true,
  }
  const isDarkPage = darkPagesWhiteLogo[pathName.pathname]
  const isDarkMobileLogo = mobileDarkLogo[pathName.pathname]
  let headerLogo
  if ((isMobile && !isDarkMobileLogo) || (isMobile && isSticky)) {
    headerLogo = Mobilelogo
  } else if (isMobile && isDarkMobileLogo && !isSticky) {
    headerLogo = MobileDarkLogo
  } else if (isDarkPage || isDark) {
    headerLogo = whiteLogo
  } else {
    headerLogo = logo
  }

  const navigateToPage = page => {
    setIsOpen(false)
  }
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={{
          position: isSticky ? "fixed" : "absolute",
        }}
      >
        <div
          className="navbar-header"
          style={{
            backgroundColor: isOpen
              ? "transparent"
              : isDark
              ? "black"
              : "transparent",
          }}
        >
          {isOpen ? (
            <div />
          ) : (
            <Link to="/">
              <img src={headerLogo} alt="" width={!isMobile && "180"} />
            </Link>
          )}

          <button
            type="button"
            className={`btn border border-3 position-relative avatar-sm rounded-circle bg-transparent d-flex justify-content-center align-items-center p-4  Heading8  ${
              isWhiteButton || isOpen
                ? "textWhite btn-light menu-button-top-white border-white"
                : "textDark btn-light menu-button-top-black border-dark"
            } `}
            onClick={toggle}
          >
            {isOpen ? "X" : "MENU"}
          </button>
        </div>
      </header>
      <Collapse isOpen={isOpen} navbar>
        <div
          id="layout-wrapper"
          className={`fullscreen-menu ${isOpen ? "active" : "inactive"}`}
        >
          <div className="fullscreen-menu-inner d-flex flex-column justify-content-center align-items-center">
            {items.map((item, index) => (
              <Link
                to={`/${item.toLowerCase()}`}
                key={index}
                className="menu-item-full-screen mb-3 "
                onClick={() => navigateToPage(item.toLowerCase())}
              >
                {item}
              </Link>
            ))}
            <div className="menu-social-images-container d-flex justify-content-between align-items-center mt-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/pinetree_studios_london/"
              >
                <img src={insta} alt="" className="menu-social-images me-2" />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/pinetree_product_photography-112872917231179"
              >
                <img src={fb} alt="" className="menu-social-images me-2" />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/447592028499"
              >
                <img
                  src={whatsapp}
                  alt=""
                  className="menu-social-images me-2"
                />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCVQqMwt0rny0OPq9JRF4eNw"
              >
                <img src={yt} alt="" className="menu-social-images me-2" />
              </a>
            </div>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
