import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import "locomotive-scroll/dist/locomotive-scroll.css"

import store from "./store"
import Hotjar from "@hotjar/browser"
const siteId = 5048375
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
)

serviceWorker.unregister()
