import React from "react"
import { Container, Row, Col } from "reactstrap"
import footerLogo1 from "assets/images/footerLogoSmall.svg"
import footerLogo2 from "assets/images/White_logo.png"
import { Link } from "react-router-dom"
import useIsMobile from "hooks/useIsMobile"

const FooterSection = () => {
  const isMobile = useIsMobile()
  const logo = isMobile ? footerLogo1 : footerLogo2
  return (
    <React.Fragment>
      <section className=" Paragraph3Regular textWhite bg-black d-flex justify-content-between">
        <Container fluid>
          <Row>
            <Col md={7} sm={12} className="ms-md-5 py-md-5 py-3">
              {" "}
              <img src={logo} alt="" width={!isMobile && "200px"} className=" d-none d-md-block"/>
            </Col>
            <Col md={4} sm={12} className="align-self-center mb-3 ms-3">
              <Row className="">
                <Col xs={3} md={2} className="">
                  <Link
                    to="/portfolio"
                    className="text-white text-decoration-none"
                  >
                    Portfolio
                  </Link>
                </Col>
                <Col xs={2} className="">
                  <Link to="/about" className="text-white text-decoration-none">
                    About
                  </Link>
                </Col>
                <Col xs={2} className="">
                  <Link to="/blogs" className="text-white text-decoration-none">
                    Blogs
                  </Link>
                </Col>
                <Col xs={2} className="">
                  <Link
                    to="/contact"
                    className="text-white text-decoration-none"
                  >
                    Contact
                  </Link>
                </Col>
              </Row>
            </Col>
          
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default FooterSection
